<template>

  <div>              

      <div class="modal modal-full-screen modal-fx-fadeInScale" :class="{'is-active':isActiveWindow}">
        <div class="modal-background" @click="cancelItem"></div>
        <div class="modal-card">
          <button class="delete" aria-label="hasSeenNotice" @click="cancelItem"></button>
          
            <!-- <header class="modal-card-head">
              <p class="modal-card-title">Edit Item</p>
              <button class="delete" aria-label="hasSeenNotice" @click="saveItem"></button>
            </header> -->

            <section class="modal-card-body"> 

              <div v-if="item.action == 'Read'">
                <div class="title">{{item.title}}</div>
                <!-- <div class="details">{{item.details}}</div> -->
                <div class="details">
                  <div v-for="line in item.details.split('\n')" :key="line">{{line}}<br></div>
                </div>
                <hr>
                <div class="buttons">
                  <span class="button is-medium is-info" @click="cancelItem">Done</span>
                </div>  
              </div>

              <div v-else>
                <h4>{{item.action}} {{item.heading}} Title</h4>
                <input class="input" type="text" v-model="title" placeholder="Write title..." />

                <h4>{{item.action}} {{item.heading}} Details</h4>
                <textarea class="input" v-model="details"></textarea>
                <div class="buttons">
                  <span :disabled="!somethingChanged" class="button is-medium" :class="[item.action == 'Edit' ? 'is-success' : 'is-primary']" @click="saveItem">Save</span>
                  <span class="button is-medium is-light" @click="cancelItem">Cancel</span>
                </div>        
              </div>      

            </section>
            <!-- <footer class="modal-card-foot">
              <button :disabled="!somethingChanged" class="button is-primary" @click="saveItem">Done</button>
            </footer>   -->      

        </div>
      </div>




  </div>

</template>



<script>

export default {

  data: function() {
    return {
      title: '',
      details: '',
      isActiveWindow: true,
    }
  },

  props: {
    item: Object,
  },

  computed: {
    somethingChanged() {
      return this.item.title != this.title || this.item.details != this.details;
    }
  },

  mounted() {
    if (this.item) {
      this.title = this.item.title;
      this.details = this.item.details;
    } 
  },

  methods: {

    saveItem: function() {
      let self = this;
      let returnItem = {
        id: self.item.id,
        edited: self.somethingChanged ? true : false,
        title: self.title ? self.title : 'No title',
        details: self.details,
        isNewItem: self.item.action == 'Write New' ? true : false,
      }
      this.$emit('sendItem', returnItem);
      this.isActiveWindow = false;
    },

    cancelItem: function() {
      let self = this;
      let returnItem = {
        id: self.item.id,
        edited: false,
        title: self.title ? self.title : 'No title',
        details: self.details,
        isNewItem: false,
      }
      this.$emit('sendItem', returnItem);
      this.isActiveWindow = false;
    },


  }

}

</script>

<style lang="scss" scoped>
  
  .modal-card {
    width: 95%;
    height: 95%;
    max-width: 1200px;  
    border-radius: 6px;
    position: relative;
    .modal-card-body {
      max-height: none;
      padding: 4rem 3rem 3rem;
    }
    .delete {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .title {
      font-size: 1.5rem;
    }
    // .details {
    //   background: #f9f9f9;
    //   padding: 2rem;
    //   border-radius: 6px;
    // }
  }

  h4 {
    font-size: 1.5rem;
  }

  input[type="text"] {
    padding: 25px;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 2rem;
    // border-width: 2px;
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: green;
    }
  }
  textarea {
    //overflow: hidden;
    min-height: 300px;
    min-height: 40vh;
    padding: 20px 25px;
    box-shadow: none;
    margin-bottom: 2rem;
    border-radius: 0;
    overflow: scroll;
    // border-width: 2px;
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: green;
    }
  }

  @media only screen and (max-width: 600px) {
    .modal-card {
      .modal-card-body {
        padding: 3rem 1rem 1rem;
      }
    }
  }



</style>